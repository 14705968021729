import { FC } from 'react';
import { Tooltip, Text } from 'components/primitives'
import { getContractDefinition } from 'utils/collections';

type Props = {
  collectionId: string
}

export const OfficialCollection: FC<Props> = ({ collectionId }) => {
  const { isOfficial } = getContractDefinition(collectionId)

  if (!isOfficial) return null

  return <Tooltip
    sideOffset={4}
    content={
      <Text style="body3" css={{ display: 'block' }}>
        Official Collection
      </Text>
    }
  >
    <img src="/icons/official-collection.svg"/>
  </Tooltip>
}
